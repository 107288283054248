import { Injectable } from '@angular/core';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Subscription } from 'rxjs';
import { IdTokenJwt } from './models/id-token-jwt';
const REQUESTED_ROUTE = 'signpostRequestedRouteBeforeAuth';

@Injectable()
export class AuthService {
  isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(
    map((res) => res.isAuthenticated)
  );

  private checkAuthSub?: Subscription;

  constructor(
    public oidcSecurityService: OidcSecurityService
  ) {}

  goToIdentityLoginPage() {
    // store landing url in local storage

    localStorage.setItem(REQUESTED_ROUTE, window.location.pathname);
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe();
  }

  bearerToken() {
    return this.oidcSecurityService.getAccessToken();
  }

  destroy() {
    this.checkAuthSub && this.checkAuthSub.unsubscribe();
  }

  roleIncludesStudent(res: LoginResponse) {
    if (res.idToken) {
      const decodedIdToken: IdTokenJwt = this.decodeJwt(res.idToken);
      return decodedIdToken.role.includes('Student');
    } else {
      return false;
    }
  }

  decodeJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
