const apiBaseUrl = 'https://signpost-api-dev.nessy.com/';
const notificationsAPIPath = 'https://notifications-api-dev.nessy.com/';
const accountAPIPath = 'https://account-api-dev.nessy.com/';
const gamesAPIPath = 'https://students-dev.nessy.com/';

//* this needs an interface so adding causes thing to break and easier to see
export const environment = {
  settings: {
    production: false,
    clientId: 'nessy.games.client',
    authAuthority: 'https://nessy-id-dev.nessy.com',
    cookieDomain: 'nessy.com',
  },
  gamesBaseUrl: {
    application: `${gamesAPIPath}learning/{license}?rToken={sessionToken}`,
  },
  api: {
    root: apiBaseUrl,
    notificationsRoot: notificationsAPIPath,
    studentMeta: `${apiBaseUrl}applications`,

    notifications: {
      getNotifications: `${notificationsAPIPath}notifications`,
      markRead: `${notificationsAPIPath}markRead`,
    },
    avatar: {
      getAvatarItems: `${accountAPIPath}avatarItems`,
      upsertAvatarItems: `${accountAPIPath}upsertAvatarItems`,
    },
  },
};
